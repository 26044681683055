<template>
  <v-btn
      :outlined="true"
      :loading="loading"
      :disabled="loading"
      color="success"
      @click="init"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <v-icon>
      mdi-plus
    </v-icon>

    <slot/>

    <v-dialog
        v-model="dialog"
        :persistent="true"
        :scrollable="true"
        max-width="1000"
    >
      <v-card>
        <v-card-title>
          Vytvoriť položku

          <v-spacer/>

          <v-btn
              :icon="true"
              color="default"
              @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form
              @submit.stop.prevent="submit"
          >
            <v-row>
              <v-col
                  cols="12"
                  lg="6"
              >
                <ImageFiles
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Fotografia"
                    :persistent-hint="true"
                    hint="Odporúčaný formát 1:1"
                    :File="ItemForm.Photo"
                    :errorsFile="errors.Photo"
                    @setFile="setPhoto"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="6"
              >
                <ImageFiles
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Výkres"
                    :persistent-hint="true"
                    hint="Odporúčaný formát 16:9"
                    :File="ItemForm.Dimension"
                    :errorsFile="errors.Dimension"
                    @setFile="setDimension"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="6"
              >
                <v-text-field
                    v-model="ItemForm.orderNumber"
                    :error-messages="errors.orderNumber"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Objednávkové číslo"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="6"
              >
                <v-text-field
                    v-model="ItemForm.productType"
                    :error-messages="errors.productType"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Produktový typ"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="3"
              >
                <v-text-field
                    v-model="ItemForm.amp"
                    :error-messages="errors.amp"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Počet ampérov"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="3"
              >
                <v-text-field
                    v-model="ItemForm.poles"
                    :error-messages="errors.poles"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Počet pólov"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="3"
              >
                <v-text-field
                    v-model="ItemForm.size1"
                    :error-messages="errors.size1"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Rozmer č.1 (Ø A)"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="3"
              >
                <v-text-field
                    v-model="ItemForm.size2"
                    :error-messages="errors.size2"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Rozmer č.2 (A)"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="3"
              >
                <v-text-field
                    v-model="ItemForm.size3"
                    :error-messages="errors.size3"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Rozmer č.3 (B)"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="3"
              >
                <v-text-field
                    v-model="ItemForm.size4"
                    :error-messages="errors.size4"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Rozmer č.4 (Ø B)"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="3"
              >
                <v-text-field
                    v-model="ItemForm.size5"
                    :error-messages="errors.size5"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Rozmer č.5 (C)"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="3"
              >
                <v-text-field
                    v-model="ItemForm.size6"
                    :error-messages="errors.size6"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Rozmer č.6 (Ø C)"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="3"
              >
                <v-text-field
                    v-model="ItemForm.size7"
                    :error-messages="errors.size7"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Rozmer č.7 (Ø d)"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="3"
              >
                <v-text-field
                    v-model="ItemForm.size8"
                    :error-messages="errors.size8"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Rozmer č.8 (D)"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="3"
              >
                <v-text-field
                    v-model="ItemForm.size9"
                    :error-messages="errors.size9"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Rozmer č.9 (Ø D)"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="3"
              >
                <v-text-field
                    v-model="ItemForm.size10"
                    :error-messages="errors.size10"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Rozmer č.10 (Ø d1)"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="3"
              >
                <v-text-field
                    v-model="ItemForm.size11"
                    :error-messages="errors.size11"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Rozmer č.11 (Ø D)"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="3"
              >
                <v-text-field
                    v-model="ItemForm.size12"
                    :error-messages="errors.size12"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Rozmer č.12 (D1)"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="3"
              >
                <v-text-field
                    v-model="ItemForm.size13"
                    :error-messages="errors.size13"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Rozmer č.13 (D2)"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="3"
              >
                <v-text-field
                    v-model="ItemForm.size14"
                    :error-messages="errors.size14"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Rozmer č.14 (E)"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="3"
              >
                <v-text-field
                    v-model="ItemForm.size15"
                    :error-messages="errors.size15"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Rozmer č.15 (F)"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="3"
              >
                <v-text-field
                    v-model="ItemForm.size16"
                    :error-messages="errors.size16"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Rozmer č.16 (Ø G)"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="3"
              >
                <v-text-field
                    v-model="ItemForm.size17"
                    :error-messages="errors.size17"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Rozmer č.17 (Ø H)"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="3"
              >
                <v-text-field
                    v-model="ItemForm.size18"
                    :error-messages="errors.size18"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Rozmer č.18 (Ø I)"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="3"
              >
                <v-text-field
                    v-model="ItemForm.size19"
                    :error-messages="errors.size19"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Rozmer č.19 (E1)"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="3"
              >
                <v-text-field
                    v-model="ItemForm.size20"
                    :error-messages="errors.size20"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Rozmer č.20 (E2)"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="3"
              >
                <v-text-field
                    v-model="ItemForm.size21"
                    :error-messages="errors.size21"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Rozmer č.21 (F)"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="3"
              >
                <v-text-field
                    v-model="ItemForm.size22"
                    :error-messages="errors.size22"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Rozmer č.22 (G)"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="3"
              >
                <v-text-field
                    v-model="ItemForm.size23"
                    :error-messages="errors.size23"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Rozmer č.23 (H)"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="3"
              >
                <v-text-field
                    v-model="ItemForm.size24"
                    :error-messages="errors.size24"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Rozmer č.24 (J)"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="3"
              >
                <v-text-field
                    v-model="ItemForm.size25"
                    :error-messages="errors.size25"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Rozmer č.25 (K)"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="3"
              >
                <v-text-field
                    v-model="ItemForm.size26"
                    :error-messages="errors.size26"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Rozmer č.26 (L)"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="3"
              >
                <v-text-field
                    v-model="ItemForm.size27"
                    :error-messages="errors.size27"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Rozmer č.27 (R1/R2)"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="3"
              >
                <v-text-field
                    v-model="ItemForm.size28"
                    :error-messages="errors.size28"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Rozmer č.28 (R)"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="3"
              >
                <v-text-field
                    v-model="ItemForm.standard1"
                    :error-messages="errors.standard1"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Štandard č.1"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="3"
              >
                <v-text-field
                    v-model="ItemForm.standard2"
                    :error-messages="errors.standard2"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Štandard č.2"
                />
              </v-col>

              <v-col
                  cols="12"
              >
                <v-btn
                    type="submit"
                    :rounded="true"
                    :outlined="true"
                    :loading="loading"
                    :disabled="loading"
                    color="success"
                >
                  <v-icon>
                    mdi-plus
                  </v-icon>

                  Vytvoriť
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>
  import Items from '@/services/items'

  import ImageFiles from '@/components/Files/Image'

  export default {
    name: 'Create',
    components: {
      ImageFiles
    },
    data() {
      return {
        loading: false,
        dialog: false,
        ItemForm: {},
        errors: {}
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      closeDialog() {
        this.dialog = false
      },
      loadData() {
        this.dialog = true

        this.ItemForm = {
          Photo: null,
          Dimension: null,
          orderNumber: '',
          productType: '',
          amp: '',
          poles: '',
          size1: '',
          size2: '',
          size3: '',
          size4: '',
          size5: '',
          size6: '',
          size7: '',
          size8: '',
          size9: '',
          size10: '',
          size11: '',
          size12: '',
          size13: '',
          size14: '',
          size15: '',
          size16: '',
          size17: '',
          size18: '',
          size19: '',
          size20: '',
          size21: '',
          size22: '',
          size23: '',
          size24: '',
          size25: '',
          size26: '',
          size27: '',
          size28: '',
          standard1: '',
          standard2: ''
        }

        this.errors = {}
      },
      setPhoto(Photo) {
        this.ItemForm.Photo = Photo
      },
      setDimension(Dimension) {
        this.ItemForm.Dimension = Dimension
      },
      async submit() {
        this.loading = true

        await Items
          .create({
            data: this.ItemForm
          })
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })

            this.closeDialog()

            this.$emit('init')
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 401:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.closeDialog()

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
