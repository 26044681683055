import Vue from 'vue'

class Cron {
  async processItems() {
    return await Vue.axios
      .get('cron/process/items')
  }
}

const cron = new Cron()

export default cron
