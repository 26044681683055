import Vue from 'vue'

import Qs from 'qs'

class Items {
  async index({params}) {
    return await Vue.axios
      .get('app/items', {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async create({data}) {
    return await Vue.axios
      .post('app/items', data)
  }

  async read({id}) {
    return await Vue.axios
      .get('app/items/' + id)
  }

  async update({id, data}) {
    return await Vue.axios
      .put('app/items/' + id, data)
  }

  async delete({id}) {
    return await Vue.axios
      .delete('app/items/' + id)
  }
}

const items = new Items()

export default items
