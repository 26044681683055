import Register from '@/services/register'

const state = {
  Admin: {
    type: {},
    Filter: {
      order: {},
      by: {},
      limit: {}
    }
  },
  Image: {
    Resource: {
      type: {}
    }
  },
  Import: {
    status: {},
    Filter: {
      order: {},
      by: {},
      limit: {}
    }
  },
  Item: {
    Filter: {
      order: {},
      by: {},
      limit: {}
    }
  },
  ItemMutation: {
    Filter: {
      order: {},
      by: {},
      limit: {}
    }
  },
  Mutation: {
    Filter: {
      order: {},
      by: {},
      limit: {}
    }
  },
  Zip: {
    Resource: {
      type: {}
    }
  }
}

const mutations = {
  SET_ADMIN(state, Admin) {
    state.Admin = Admin
  },
  SET_IMAGE(state, Image) {
    state.Image = Image
  },
  SET_IMPORT(state, Import) {
    state.Import = Import
  },
  SET_ITEM(state, Item) {
    state.Item = Item
  },
  SET_ITEM_MUTATION(state, ItemMutation) {
    state.ItemMutation = ItemMutation
  },
  SET_MUTATION(state, Mutation) {
    state.Mutation = Mutation
  },
  SET_ZIP(state, Zip) {
    state.Zip = Zip
  }
}

const actions = {
  async callIndex({commit}) {
    return await Register
      .index()
      .then((response) => {
        commit('SET_ADMIN', response.data.data.Admin)

        commit('SET_IMAGE', response.data.data.Image)

        commit('SET_IMPORT', response.data.data.Import)

        commit('SET_ITEM', response.data.data.Item)

        commit('SET_ITEM_MUTATION', response.data.data.ItemMutation)

        commit('SET_MUTATION', response.data.data.Mutation)

        commit('SET_ZIP', response.data.data.Zip)
      })
      .catch(() => {
        commit('SET_ADMIN', {
          type: {},
          Filter: {
            order: {},
            by: {},
            limit: {}
          }
        })

        commit('SET_IMAGE', {
          Resource: {
            type: {}
          }
        })

        commit('SET_IMPORT', {
          status: {},
          Filter: {
            order: {},
            by: {},
            limit: {}
          }
        })

        commit('SET_ITEM', {
          Filter: {
            order: {},
            by: {},
            limit: {}
          }
        })

        commit('SET_ITEM_MUTATION', {
          Filter: {
            order: {},
            by: {},
            limit: {}
          }
        })

        commit('SET_MUTATION', {
          Filter: {
            order: {},
            by: {},
            limit: {}
          }
        })

        commit('SET_ZIP', {
          Resource: {
            type: {}
          }
        })
      })
  }
}

const getters = {
  getAdminType(state) {
    return Object.values(state.Admin.type)
  },
  getAdminFilterOrder(state) {
    return Object.values(state.Admin.Filter.order)
  },
  getAdminFilterBy(state) {
    return Object.values(state.Admin.Filter.by)
  },
  getAdminFilterLimit(state) {
    return Object.values(state.Admin.Filter.limit)
  },
  getImageResourceType(state) {
    return Object.values(state.Image.Resource.type)
  },
  getImportStatus(state) {
    return Object.values(state.Import.status)
  },
  getImportFilterOrder(state) {
    return Object.values(state.Import.Filter.order)
  },
  getImportFilterBy(state) {
    return Object.values(state.Import.Filter.by)
  },
  getImportFilterLimit(state) {
    return Object.values(state.Import.Filter.limit)
  },
  getItemFilterOrder(state) {
    return Object.values(state.Item.Filter.order)
  },
  getItemFilterBy(state) {
    return Object.values(state.Item.Filter.by)
  },
  getItemFilterLimit(state) {
    return Object.values(state.Item.Filter.limit)
  },
  getItemMutationFilterOrder(state) {
    return Object.values(state.ItemMutation.Filter.order)
  },
  getItemMutationFilterBy(state) {
    return Object.values(state.ItemMutation.Filter.by)
  },
  getItemMutationFilterLimit(state) {
    return Object.values(state.ItemMutation.Filter.limit)
  },
  getMutationFilterOrder(state) {
    return Object.values(state.Mutation.Filter.order)
  },
  getMutationFilterBy(state) {
    return Object.values(state.Mutation.Filter.by)
  },
  getMutationFilterLimit(state) {
    return Object.values(state.Mutation.Filter.limit)
  },
  getZipResourceType(state) {
    return Object.values(state.Zip.Resource.type)
  }
}

const register = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

export default register
