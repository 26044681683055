import Vue from 'vue'

import Qs from 'qs'

class Mutations {
  async index({params}) {
    return await Vue.axios
      .get('app/mutations', {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async sort({id, oldIndex, newIndex}) {
    return await Vue.axios
      .post('app/mutations/sort/' + id + '/' + oldIndex + '/' + newIndex)
  }

  async create({data}) {
    return await Vue.axios
      .post('app/mutations', data)
  }

  async read({id}) {
    return await Vue.axios
      .get('app/mutations/' + id)
  }

  async update({id, data}) {
    return await Vue.axios
      .put('app/mutations/' + id, data)
  }

  async delete({id}) {
    return await Vue.axios
      .delete('app/mutations/' + id)
  }
}

const mutations = new Mutations()

export default mutations
