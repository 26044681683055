<template>
  <v-card>
    <v-card-title>
      Vyžiadať nové heslo do účtu
    </v-card-title>

    <v-card-text>
      <v-alert
          v-if="thanks"
          type="success"
          class="ma-0"
      >
        Vyžiadanie nového hesla bolo odoslané. Skontroluj si e-mail. :)
      </v-alert>

      <v-form
          v-else
          @submit.stop.prevent="submit"
      >
        <v-row>
          <v-col
              cols="12"
          >
            <v-text-field
                v-model="UserForm.username"
                :filled="true"
                :dense="true"
                hide-details="auto"
                label="Užívateľské meno"
            />
          </v-col>

          <v-col
              cols="12"
          >
            <v-btn
                type="submit"
                :rounded="true"
                :outlined="true"
                :loading="loading"
                :disabled="loading"
                color="primary"
            >
              <v-icon>
                mdi-send
              </v-icon>

              Vyžiadať nové heslo
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
  import Auth from '@/services/auth'

  export default {
    name: 'RequestNewPassword',
    data() {
      return {
        loading: false,
        thanks: false,
        UserForm: {}
      }
    },
    metaInfo() {
      return {
        title: 'Vyžiadať nové heslo'
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        this.loadData()
      },
      setBreadcrumbs() {
        this.$store.dispatch('layout/setBreadcrumbs', {
          breadcrumbs: [
            {
              text: 'Prihlásiť sa',
              to: '/login'
            },
            {
              text: 'Vyžiadať nové heslo',
              to: '/request-new-password'
            }
          ]
        })
      },
      loadData() {
        this.setBreadcrumbs()

        this.thanks = false

        this.UserForm = {
          username: ''
        }
      },
      async submit() {
        this.loading = true

        await Auth
          .requestNewPassword({
            data: this.UserForm
          })
          .then(() => {
            this.thanks = true
          })
          .catch((error) => {
            switch (error.response.status) {
              case 401:
              case 403:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
