import Auth from '@/services/auth'

const state = {
  isGranted: false,
  User: null
}

const mutations = {
  SET_IS_GRANTED(state, isGranted) {
    state.isGranted = isGranted
  },
  SET_USER(state, User) {
    state.User = User
  }
}

const actions = {
  async callUser({commit}) {
    return await Auth
      .user()
      .then((response) => {
        commit('SET_IS_GRANTED', response.data.data.isGranted)

        commit('SET_USER', response.data.data.User)
      })
      .catch(() => {
        commit('SET_IS_GRANTED', false)

        commit('SET_USER', null)
      })
  }
}

const getters = {}

const auth = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

export default auth
