import Vue from 'vue'

class Files {
  async uploadImage({data}) {
    return await Vue.axios
      .post('files/upload/image', data)
  }

  async uploadZip({data}) {
    return await Vue.axios
      .post('files/upload/zip', data)
  }
}

const files = new Files()

export default files
