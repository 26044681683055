import Vue from 'vue'

import Qs from 'qs'

class Imports {
  async index({params}) {
    return await Vue.axios
      .get('app/imports', {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async create({data}) {
    return await Vue.axios
      .post('app/imports', data)
  }
}

const imports = new Imports()

export default imports
