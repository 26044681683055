<template>
  <v-card>
    <v-card-title>
      Mutácie ({{ count }})

      <v-spacer/>

      <Create
          :icon="true"
          @init="init"
      />

      &nbsp;

      <v-btn
          :icon="true"
          :outlined="true"
          :loading="loading"
          :disabled="loading"
          color="default"
          @click="init"
      >
        <v-icon>
          mdi-refresh
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col
            cols="12"
        >
          <v-text-field
              v-model="params.Filters.query"
              :error-messages="errors.Filters && errors.Filters.query"
              :outlined="true"
              :dense="true"
              hide-details="auto"
              label="Vyhľadávanie"
              :clearable="true"
              @change="setFilter"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col
            cols="12"
        >
          <v-expansion-panels
              v-model="expansionPanelsFilter"
              :multiple="true"
          >
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span>
                  <v-icon
                      color="info"
                  >
                    mdi-filter
                  </v-icon>

                  Filtre
                </span>
              </v-expansion-panel-header>

              <v-expansion-panel-content/>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <v-row>
        <v-col
            cols="12"
        >
          <v-card>
            <v-card-text>
              <v-progress-linear
                  v-if="loading"
                  :indeterminate="true"
              />

              <v-simple-table>
                <template
                    v-slot:default
                >
                  <thead>
                    <tr>
                      <th>
                        Vlajka
                      </th>

                      <th>
                        Názov
                      </th>

                      <th>
                        ISO
                      </th>

                      <th
                          class="fixed"
                      >
                        <a
                            @click="setOrder($store.state.register.Mutation.Filter.order['mu.sort'].value)"
                        >
                          <v-icon
                              v-if="params.order === $store.state.register.Mutation.Filter.order['mu.sort'].value"
                          >
                            {{
                              params.by === $store.state.register.Mutation.Filter.by['ASC'].value
                                ? 'mdi-chevron-up'
                                : 'mdi-chevron-down'
                            }}
                          </v-icon>

                          Poradie
                        </a>
                      </th>

                      <th
                          class="text-right fixed"
                      >
                        <v-icon
                            :color="
                              isSortable()
                                ? 'green'
                                : 'red'
                            "
                        >
                          mdi-sort
                        </v-icon>
                      </th>
                    </tr>
                  </thead>

                  <draggable
                      v-model="mutations"
                      tag="tbody"
                      :disabled="!isSortable()"
                      @start="drag = true"
                      @end="drag = false"
                      @change="sortData"
                  >
                    <template
                        v-if="mutations.length > 0"
                    >
                      <tr
                          v-for="(Mutation, index) in mutations"
                          :key="Mutation.id"
                      >
                        <td>
                          <silent-box
                              v-if="Mutation.Flag"
                              :image="{
                                src: loadLinkByPath('files/generate/image/original/' + Mutation.Flag.token)
                              }"
                          >
                            <template
                                v-slot:silentbox-item
                            >
                              <v-img
                                  :src="loadLinkByPath('files/generate/image/thumbnail/20/15/' + Mutation.Flag.token)"
                                  alt="Vlajka"
                                  max-width="20px"
                              />
                            </template>
                          </silent-box>

                          <template
                              v-else
                          >
                            <v-icon
                                color="error"
                            >
                              mdi-close
                            </v-icon>
                          </template>
                        </td>

                        <td
                            class="fixed"
                        >
                          <span
                              class="text-truncate"
                              style="max-width: 300px"
                          >
                            {{ Mutation.title }}
                          </span>
                        </td>

                        <td>
                          {{ Mutation.iso }}
                        </td>

                        <td>
                          {{ ((params.page - 1) * params.limit) + index + 1 }}
                        </td>

                        <td
                            class="text-right fixed"
                        >
                          <Update
                              :icon="true"
                              :id="Mutation.id"
                              @init="init"
                          />

                          &nbsp;

                          <Delete
                              :icon="true"
                              :id="Mutation.id"
                              @init="init"
                          />
                        </td>
                      </tr>
                    </template>

                    <template
                        v-else
                    >
                      <tr>
                        <td
                            colspan="5"
                            class="text-center"
                        >
                          Nenašli sa žiadne mutácie.
                        </td>
                      </tr>
                    </template>
                  </draggable>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row
          v-if="pages !== 0"
          align="center"
      >
        <v-col
            cols="4"
        >
          <a
              v-for="(limit, i) in $store.getters['register/getMutationFilterLimit']"
              :key="i"
              @click="setLimit(limit.value)"
          >
            {{ limit.text }}
          </a>
        </v-col>

        <v-spacer/>

        <v-col
            cols="auto"
        >
          <v-btn
              :icon="true"
              :outlined="true"
              :loading="loading"
              :disabled="params.page === 1 || loading"
              color="primary"
              @click="previousPage"
          >
            <v-icon>
              mdi-chevron-left
            </v-icon>
          </v-btn>
        </v-col>

        <v-col
            cols="auto"
        >
          {{ params.page }} / {{ pages }}
        </v-col>

        <v-col
            cols="auto"
        >
          <v-btn
              :icon="true"
              :outlined="true"
              :loading="loading"
              :disabled="params.page === pages || loading"
              color="primary"
              @click="nextPage"
          >
            <v-icon>
              mdi-chevron-right
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import Qs from 'qs'
  import QueryTypes from 'query-types'

  import draggable from 'vuedraggable'

  import Mutations from '@/services/mutations'

  import Create from '@/components/Mutations/Create'
  import Update from '@/components/Mutations/Update'
  import Delete from '@/components/Mutations/Delete'

  export default {
    name: 'Mutations',
    components: {
      draggable,
      Create,
      Update,
      Delete
    },
    data() {
      return {
        expansionPanelsFilter: [],
        loading: false,
        count: 0,
        pages: 1,
        mutations: [],
        params: {
          Filters: {
            query: ''
          },
          order: this.$store.state.register.Mutation.Filter.order['mu.sort'].value,
          by: this.$store.state.register.Mutation.Filter.by['ASC'].value,
          limit: this.$store.state.register.Mutation.Filter.limit[25].value,
          page: 1
        },
        errors: {}
      }
    },
    metaInfo() {
      return {
        title: 'Mutácie'
      }
    },
    beforeMount() {
      this.beforeInit()
    },
    mounted() {
      this.init()
    },
    methods: {
      beforeInit() {
        this.params = {
          ...this.params,
          ...Qs.parse(QueryTypes.parseObject(this.$route.query))
        }
      },
      init() {
        this.loadData()
      },
      setFilter() {
        this.params.page = 1

        this.init()
      },
      setOrder(order) {
        this.params.order = order

        switch (this.params.by) {
          case this.$store.state.register.Mutation.Filter.by['ASC'].value:
            this.params.by = this.$store.state.register.Mutation.Filter.by['DESC'].value

            break

          case this.$store.state.register.Mutation.Filter.by['DESC'].value:
            this.params.by = this.$store.state.register.Mutation.Filter.by['ASC'].value

            break
        }

        this.params.page = 1

        this.init()
      },
      setLimit(limit) {
        this.params.limit = limit

        this.params.page = 1

        this.init()
      },
      previousPage() {
        this.params.page -= 1

        window.scrollTo(0, 0)

        this.init()
      },
      nextPage() {
        this.params.page += 1

        window.scrollTo(0, 0)

        this.init()
      },
      isSortable() {
        return (
            this.params.Filters.query === ''
            && this.params.order === this.$store.state.register.Mutation.Filter.order['mu.sort'].value
            && this.params.by === this.$store.state.register.Mutation.Filter.by['ASC'].value
        )
      },
      setBreadcrumbs() {
        this.$store.dispatch('layout/setBreadcrumbs', {
          breadcrumbs: [
            {
              text: 'Mutácie',
              to: '/mutations'
            }
          ]
        })
      },
      loadData() {
        this.setBreadcrumbs()

        this.loading = true

        this.$router.replace('?' + Qs.stringify(this.params))

        Mutations
          .index({
            params: this.params
          })
          .then((response) => {
            this.count = response.data.data.count

            this.pages = response.data.data.pages

            this.mutations = response.data.data.mutations

            this.errors = {}
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 401:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      },
      sortData(event) {
        this.loading = true

        Mutations
          .sort({
            id: event.moved.element.id,
            oldIndex: ((this.params.page - 1) * this.params.limit) + event.moved.oldIndex,
            newIndex: ((this.params.page - 1) * this.params.limit) + event.moved.newIndex
          })
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })
          })
          .catch((error) => {
            switch (error.response.status) {
              case 401:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      },
      loadLinkByPath(path) {
        return process.env.VUE_APP_AXIOS_URL + path
      }
    }
  }
</script>
