<template>
  <v-btn
      :outlined="true"
      :loading="loading"
      :disabled="loading"
      color="warning"
      @click="init"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <v-icon>
      mdi-pencil
    </v-icon>

    <slot/>

    <v-dialog
        v-if="ItemMutation"
        v-model="dialog"
        :persistent="true"
        :scrollable="true"
        max-width="1000"
    >
      <v-card>
        <v-card-title>
          Upraviť mutáciu položky
          &nbsp;
          <v-img
              :src="loadLinkByPath('files/generate/image/thumbnail/20/15' + (
                ItemMutation.Mutation.Flag.token
                  ? '/' + ItemMutation.Mutation.Flag.token
                  : ''
              ))"
              :alt="ItemMutation.Mutation.iso"
              max-width="20px"
          />
          &nbsp;
          {{ ItemMutation.Item.orderNumber }}

          <v-spacer/>

          <v-btn
              :icon="true"
              color="default"
              @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form
              @submit.stop.prevent="submit"
          >
            <v-row>
              <v-col
                  cols="12"
                  lg="6"
              >
                <v-text-field
                    v-model="ItemMutationForm.productName"
                    :error-messages="errors.productName"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Produktový názov"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="6"
              >
                <v-text-field
                    v-model="ItemMutationForm.publishedAt"
                    :error-messages="errors.publishedAt"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Dátum publikovania"
                    type="date"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="6"
              >
                <v-text-field
                    v-model="ItemMutationForm.photoText"
                    :error-messages="errors.photoText"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Text obrázku"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="6"
              >
                <v-text-field
                    v-model="ItemMutationForm.tableText"
                    :error-messages="errors.tableText"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Text tabuľky"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="6"
              >
                <v-row>
                  <v-col
                      cols="12"
                  >
                    <v-text-field
                        v-model="ItemMutationForm.parameter1"
                        :error-messages="errors.parameter1"
                        :filled="true"
                        :dense="true"
                        hide-details="auto"
                        label="Parameter č.1 (ľavý stĺpec)"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                  >
                    <v-text-field
                        v-model="ItemMutationForm.parameter2"
                        :error-messages="errors.parameter2"
                        :filled="true"
                        :dense="true"
                        hide-details="auto"
                        label="Parameter č.2 (ľavý stĺpec)"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                  >
                    <v-text-field
                        v-model="ItemMutationForm.parameter3"
                        :error-messages="errors.parameter3"
                        :filled="true"
                        :dense="true"
                        hide-details="auto"
                        label="Parameter č.3 (ľavý stĺpec)"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                  >
                    <v-text-field
                        v-model="ItemMutationForm.parameter4"
                        :error-messages="errors.parameter4"
                        :filled="true"
                        :dense="true"
                        hide-details="auto"
                        label="Parameter č.4 (ľavý stĺpec)"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                  >
                    <v-text-field
                        v-model="ItemMutationForm.parameter5"
                        :error-messages="errors.parameter5"
                        :filled="true"
                        :dense="true"
                        hide-details="auto"
                        label="Parameter č.5 (ľavý stĺpec)"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                  >
                    <v-text-field
                        v-model="ItemMutationForm.parameter6"
                        :error-messages="errors.parameter6"
                        :filled="true"
                        :dense="true"
                        hide-details="auto"
                        label="Parameter č.6 (ľavý stĺpec)"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                  >
                    <v-text-field
                        v-model="ItemMutationForm.parameter7"
                        :error-messages="errors.parameter7"
                        :filled="true"
                        :dense="true"
                        hide-details="auto"
                        label="Parameter č.7 (ľavý stĺpec)"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                  >
                    <v-text-field
                        v-model="ItemMutationForm.parameter8"
                        :error-messages="errors.parameter8"
                        :filled="true"
                        :dense="true"
                        hide-details="auto"
                        label="Parameter č.8 (ľavý stĺpec)"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                  >
                    <v-text-field
                        v-model="ItemMutationForm.parameter9"
                        :error-messages="errors.parameter9"
                        :filled="true"
                        :dense="true"
                        hide-details="auto"
                        label="Parameter č.9 (ľavý stĺpec)"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                  >
                    <v-text-field
                        v-model="ItemMutationForm.parameter10"
                        :error-messages="errors.parameter10"
                        :filled="true"
                        :dense="true"
                        hide-details="auto"
                        label="Parameter č.10 (ľavý stĺpec)"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                  >
                    <v-text-field
                        v-model="ItemMutationForm.parameter11"
                        :error-messages="errors.parameter11"
                        :filled="true"
                        :dense="true"
                        hide-details="auto"
                        label="Parameter č.11 (ľavý stĺpec)"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                  >
                    <v-text-field
                        v-model="ItemMutationForm.parameter12"
                        :error-messages="errors.parameter12"
                        :filled="true"
                        :dense="true"
                        hide-details="auto"
                        label="Parameter č.12 (ľavý stĺpec)"
                    />
                  </v-col>
                </v-row>
              </v-col>

              <v-col
                  cols="12"
                  lg="6"
              >
                <v-row>
                  <v-col
                      cols="12"
                  >
                    <v-text-field
                        v-model="ItemMutationForm.parameter13"
                        :error-messages="errors.parameter13"
                        :filled="true"
                        :dense="true"
                        hide-details="auto"
                        label="Parameter č.13 (pravý stĺpec)"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                  >
                    <v-text-field
                        v-model="ItemMutationForm.parameter14"
                        :error-messages="errors.parameter14"
                        :filled="true"
                        :dense="true"
                        hide-details="auto"
                        label="Parameter č.14 (pravý stĺpec)"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                  >
                    <v-text-field
                        v-model="ItemMutationForm.parameter15"
                        :error-messages="errors.parameter15"
                        :filled="true"
                        :dense="true"
                        hide-details="auto"
                        label="Parameter č.15 (pravý stĺpec)"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                  >
                    <v-text-field
                        v-model="ItemMutationForm.parameter16"
                        :error-messages="errors.parameter16"
                        :filled="true"
                        :dense="true"
                        hide-details="auto"
                        label="Parameter č.16 (pravý stĺpec)"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                  >
                    <v-text-field
                        v-model="ItemMutationForm.parameter17"
                        :error-messages="errors.parameter17"
                        :filled="true"
                        :dense="true"
                        hide-details="auto"
                        label="Parameter č.17 (pravý stĺpec)"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                  >
                    <v-text-field
                        v-model="ItemMutationForm.parameter18"
                        :error-messages="errors.parameter18"
                        :filled="true"
                        :dense="true"
                        hide-details="auto"
                        label="Parameter č.18 (pravý stĺpec)"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                  >
                    <v-text-field
                        v-model="ItemMutationForm.parameter19"
                        :error-messages="errors.parameter19"
                        :filled="true"
                        :dense="true"
                        hide-details="auto"
                        label="Parameter č.19 (pravý stĺpec)"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                  >
                    <v-text-field
                        v-model="ItemMutationForm.parameter20"
                        :error-messages="errors.parameter20"
                        :filled="true"
                        :dense="true"
                        hide-details="auto"
                        label="Parameter č.20 (pravý stĺpec)"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                  >
                    <v-text-field
                        v-model="ItemMutationForm.parameter21"
                        :error-messages="errors.parameter21"
                        :filled="true"
                        :dense="true"
                        hide-details="auto"
                        label="Parameter č.21 (pravý stĺpec)"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                  >
                    <v-text-field
                        v-model="ItemMutationForm.parameter22"
                        :error-messages="errors.parameter22"
                        :filled="true"
                        :dense="true"
                        hide-details="auto"
                        label="Parameter č.22 (pravý stĺpec)"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                  >
                    <v-text-field
                        v-model="ItemMutationForm.parameter23"
                        :error-messages="errors.parameter23"
                        :filled="true"
                        :dense="true"
                        hide-details="auto"
                        label="Parameter č.23 (pravý stĺpec)"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                  >
                    <v-text-field
                        v-model="ItemMutationForm.parameter24"
                        :error-messages="errors.parameter24"
                        :filled="true"
                        :dense="true"
                        hide-details="auto"
                        label="Parameter č.24 (pravý stĺpec)"
                    />
                  </v-col>
                </v-row>
              </v-col>

              <v-col
                  cols="12"
              >
                <v-btn
                    type="submit"
                    :rounded="true"
                    :outlined="true"
                    :loading="loading"
                    :disabled="loading"
                    color="warning"
                >
                  <v-icon>
                    mdi-pencil
                  </v-icon>

                  Upraviť
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>
  import { dateFormat } from '@vuejs-community/vue-filter-date-format'

  import ItemMutations from '@/services/item-mutations'

  export default {
    name: 'Update',
    props: [
      'id'
    ],
    data() {
      return {
        loading: false,
        dialog: false,
        ItemMutation: null,
        ItemMutationForm: {},
        errors: {}
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      closeDialog() {
        this.dialog = false
      },
      loadData() {
        this.dialog = false

        this.loading = true

        ItemMutations
          .read({
            id: this.id
          })
          .then((response) => {
            this.dialog = true

            this.ItemMutation = response.data.data.ItemMutation

            this.ItemMutationForm = {
              productName: response.data.data.ItemMutation.productName,
              publishedAt: (
                response.data.data.ItemMutation.publishedAt
                  ? dateFormat(new Date(response.data.data.ItemMutation.publishedAt), 'YYYY-MM-DD')
                  : ''
              ),
              photoText: response.data.data.ItemMutation.photoText,
              tableText: response.data.data.ItemMutation.tableText,
              parameter1: response.data.data.ItemMutation.parameter1,
              parameter2: response.data.data.ItemMutation.parameter2,
              parameter3: response.data.data.ItemMutation.parameter3,
              parameter4: response.data.data.ItemMutation.parameter4,
              parameter5: response.data.data.ItemMutation.parameter5,
              parameter6: response.data.data.ItemMutation.parameter6,
              parameter7: response.data.data.ItemMutation.parameter7,
              parameter8: response.data.data.ItemMutation.parameter8,
              parameter9: response.data.data.ItemMutation.parameter9,
              parameter10: response.data.data.ItemMutation.parameter10,
              parameter11: response.data.data.ItemMutation.parameter11,
              parameter12: response.data.data.ItemMutation.parameter12,
              parameter13: response.data.data.ItemMutation.parameter13,
              parameter14: response.data.data.ItemMutation.parameter14,
              parameter15: response.data.data.ItemMutation.parameter15,
              parameter16: response.data.data.ItemMutation.parameter16,
              parameter17: response.data.data.ItemMutation.parameter17,
              parameter18: response.data.data.ItemMutation.parameter18,
              parameter19: response.data.data.ItemMutation.parameter19,
              parameter20: response.data.data.ItemMutation.parameter20,
              parameter21: response.data.data.ItemMutation.parameter21,
              parameter22: response.data.data.ItemMutation.parameter22,
              parameter23: response.data.data.ItemMutation.parameter23,
              parameter24: response.data.data.ItemMutation.parameter24
            }

            this.errors = {}
          })
          .catch((error) => {
            switch (error.response.status) {
              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.closeDialog()

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      },
      async submit() {
        this.loading = true

        await ItemMutations
          .update({
            id: this.id,
            data: this.ItemMutationForm
          })
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })

            this.closeDialog()

            this.$emit('init')
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.closeDialog()

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      },
      dateFormat(input, format) {
        return dateFormat(input, format)
      },
      loadLinkByPath(path) {
        return process.env.VUE_APP_AXIOS_URL + path
      }
    }
  }
</script>
