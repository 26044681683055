<template>
  <v-row>
    <v-col
        cols="12"
    >
      ...
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'Error',
    metaInfo() {
      return {
        title: 'Chyba'
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        this.loadData()
      },
      setBreadcrumbs() {
        this.$store.dispatch('layout/setBreadcrumbs', {
          breadcrumbs: [
            {
              text: 'Chyba',
              to: '/error'
            }
          ]
        })
      },
      loadData() {
        this.setBreadcrumbs()
      }
    }
  }
</script>
