<template>
  <v-card
      class="grey lighten-3"
  >
    <v-card-title>
      Mutácie položky ({{ count }})

      <v-spacer/>

      <v-btn
          :icon="true"
          :outlined="true"
          :loading="loading"
          :disabled="loading"
          color="default"
          @click="init"
      >
        <v-icon>
          mdi-refresh
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col
            cols="12"
        >
          <v-text-field
              v-model="params.Filters.query"
              :error-messages="errors.Filters && errors.Filters.query"
              :outlined="true"
              :dense="true"
              hide-details="auto"
              label="Vyhľadávanie"
              :clearable="true"
              @change="setFilter"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col
            cols="12"
        >
          <v-expansion-panels
              v-model="expansionPanelsFilter"
              :multiple="true"
          >
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span>
                  <v-icon
                      color="info"
                  >
                    mdi-filter
                  </v-icon>

                  Filtre
                </span>
              </v-expansion-panel-header>

              <v-expansion-panel-content/>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <v-row>
        <v-col
            cols="12"
        >
          <v-card>
            <v-card-text>
              <v-progress-linear
                  v-if="loading"
                  :indeterminate="true"
              />

              <v-simple-table>
                <template
                    v-slot:default
                >
                  <thead>
                    <tr>
                      <th>
                        Mutácia
                      </th>

                      <th
                          class="fixed"
                      >
                        Produktový názov
                      </th>

                      <th>
                        <a
                            @click="setOrder($store.state.register.ItemMutation.Filter.order['itmu.publishedAt'].value)"
                        >
                          <v-icon
                              v-if="params.order === $store.state.register.ItemMutation.Filter.order['itmu.publishedAt'].value"
                          >
                            {{
                              params.by === $store.state.register.ItemMutation.Filter.by['ASC'].value
                                ? 'mdi-chevron-up'
                                : 'mdi-chevron-down'
                            }}
                          </v-icon>

                          Dátum publikácie
                        </a>
                      </th>

                      <th
                          class="text-right fixed"
                      />
                    </tr>
                  </thead>

                  <tbody>
                    <template
                        v-if="itemMutations.length > 0"
                    >
                      <tr
                          v-for="ItemMutation in itemMutations"
                          :key="ItemMutation.id"
                      >
                        <td>
                          <v-img
                              :src="loadLinkByPath('files/generate/image/thumbnail/20/15' + (
                                ItemMutation.Mutation.Flag.token
                                  ? '/' + ItemMutation.Mutation.Flag.token
                                  : ''
                              ))"
                              :alt="ItemMutation.Mutation.title"
                              max-width="20px"
                          />
                        </td>

                        <td
                            class="fixed"
                        >
                          <span
                              class="text-truncate"
                              style="max-width: 300px"
                          >
                            {{
                              ItemMutation.productName
                                ? ItemMutation.productName
                                : 'Nevyplnené'
                            }}
                          </span>
                        </td>

                        <td>
                          {{ dateFormat(new Date(ItemMutation.publishedAt), 'DD.MM.YYYY') }}
                        </td>

                        <td
                            class="text-right fixed"
                        >
                          <v-btn
                              :icon="true"
                              :outlined="true"
                              color="default"
                              @click="downloadFileByLink(loadLinkByPath('app/item-mutations/' + ItemMutation.id + '/generate/datasheet'))"
                          >
                            <v-icon>
                              mdi-download
                            </v-icon>
                          </v-btn>

                          &nbsp;

                          <Update
                              :icon="true"
                              :id="ItemMutation.id"
                              @refresh="refresh"
                              @init="init"
                          />
                        </td>
                      </tr>
                    </template>

                    <template
                        v-else
                    >
                      <tr>
                        <td
                            colspan="4"
                            class="text-center"
                        >
                          Nenašli sa žiadne mutácie položky.
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row
          v-if="pages !== 0"
          align="center"
      >
        <v-col
            cols="4"
        >
          <a
              v-for="(limit, i) in $store.getters['register/getItemMutationFilterLimit']"
              :key="i"
              @click="setLimit(limit.value)"
          >
            {{ limit.text }}
          </a>
        </v-col>

        <v-spacer/>

        <v-col
            cols="auto"
        >
          <v-btn
              :icon="true"
              :outlined="true"
              :loading="loading"
              :disabled="params.page === 1 || loading"
              color="primary"
              @click="previousPage"
          >
            <v-icon>
              mdi-chevron-left
            </v-icon>
          </v-btn>
        </v-col>

        <v-col
            cols="auto"
        >
          {{ params.page }} / {{ pages }}
        </v-col>

        <v-col
            cols="auto"
        >
          <v-btn
              :icon="true"
              :outlined="true"
              :loading="loading"
              :disabled="params.page === pages || loading"
              color="primary"
              @click="nextPage"
          >
            <v-icon>
              mdi-chevron-right
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import Qs from 'qs'
  import QueryTypes from 'query-types'

  import { dateFormat } from '@vuejs-community/vue-filter-date-format'

  import ItemMutations from '@/services/item-mutations'

  import Update from '@/components/ItemMutations/Update'

  export default {
    name: 'ItemMutations',
    props: [
      'Item'
    ],
    components: {
      Update
    },
    data() {
      return {
        expansionPanelsFilter: [],
        loading: false,
        count: 0,
        pages: 1,
        itemMutations: [],
        params: {
          Filters: {
            query: ''
          },
          order: this.$store.state.register.ItemMutation.Filter.order['itmu.publishedAt'].value,
          by: this.$store.state.register.ItemMutation.Filter.by['DESC'].value,
          limit: this.$store.state.register.ItemMutation.Filter.limit[25].value,
          page: 1
        },
        errors: {}
      }
    },
    beforeMount() {
      this.beforeInit()
    },
    mounted() {
      this.init()
    },
    methods: {
      beforeInit() {
        this.params = {
          ...this.params,
          ...Qs.parse(QueryTypes.parseObject(this.$route.query))
        }
      },
      init() {
        this.loadData()
      },
      refresh() {
        setTimeout(() => {
          this.$emit('refresh')
        }, 1000)
      },
      setFilter() {
        this.params.page = 1

        this.init()
      },
      setOrder(order) {
        this.params.order = order

        switch (this.params.by) {
          case this.$store.state.register.ItemMutation.Filter.by['ASC'].value:
            this.params.by = this.$store.state.register.ItemMutation.Filter.by['DESC'].value

            break

          case this.$store.state.register.ItemMutation.Filter.by['DESC'].value:
            this.params.by = this.$store.state.register.ItemMutation.Filter.by['ASC'].value

            break
        }

        this.params.page = 1

        this.init()
      },
      setLimit(limit) {
        this.params.limit = limit

        this.params.page = 1

        this.init()
      },
      previousPage() {
        this.params.page -= 1

        window.scrollTo(0, 0)

        this.init()
      },
      nextPage() {
        this.params.page += 1

        window.scrollTo(0, 0)

        this.init()
      },
      loadData() {
        this.loading = true

        this.$router.replace('?' + Qs.stringify(this.params))

        ItemMutations
          .indexItem({
            itemId: this.Item.id,
            params: this.params
          })
          .then((response) => {
            this.count = response.data.data.count

            this.pages = response.data.data.pages

            this.itemMutations = response.data.data.itemMutations

            this.errors = {}
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 401:
              case 403:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      },
      dateFormat(input, format) {
        return dateFormat(input, format)
      },
      loadLinkByPath(path) {
        return process.env.VUE_APP_AXIOS_URL + path
      },
      downloadFileByLink(link) {
        window.open(link)
      }
    }
  }
</script>
