import Vue from 'vue'

import VueRouter from 'vue-router'

import Session from '@/middleware/session'

import Home from '@/views/Home'
import Login from '@/views/Login'
import RequestNewPassword from '@/views/RequestNewPassword'
import SetNewPassword from '@/views/SetNewPassword'
import Error from '@/views/Error'
import Admins from '@/views/Admins'
import Mutations from '@/views/Mutations'
import Imports from '@/views/Imports'
import Items from '@/views/Items'
import Item from '@/views/Item'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '*',
    redirect: '/error'
  },
  {
    path: '/home',
    component: Home,
    meta: {
      type: 'none',
    },
    beforeEnter: Session
  },
  {
    path: '/login',
    component: Login,
    meta: {
      type: 'unlogged',
      auth: {
        isGranted: false
      }
    },
    beforeEnter: Session
  },
  {
    path: '/request-new-password',
    component: RequestNewPassword,
    meta: {
      type: 'unlogged',
      auth: {
        isGranted: false
      }
    },
    beforeEnter: Session
  },
  {
    path: '/set-new-password/:newPasswordToken',
    component: SetNewPassword,
    meta: {
      type: 'unlogged',
      auth: {
        isGranted: false
      }
    },
    beforeEnter: Session
  },
  {
    path: '/error',
    component: Error,
    meta: {
      type: 'none',
    },
    beforeEnter: Session
  },
  {
    path: '/admins',
    component: Admins,
    meta: {
      type: 'logged',
      auth: {
        isGranted: true,
        User: {
          roles: [
            'ROLE_MASTER'
          ]
        }
      }
    },
    beforeEnter: Session
  },
  {
    path: '/mutations',
    component: Mutations,
    meta: {
      type: 'logged',
      auth: {
        isGranted: true,
        User: {
          roles: [
            'ROLE_MASTER'
          ]
        }
      }
    },
    beforeEnter: Session
  },
  {
    path: '/imports',
    component: Imports,
    meta: {
      type: 'logged',
      auth: {
        isGranted: true,
        User: {
          roles: [
            'ROLE_MASTER'
          ]
        }
      }
    },
    beforeEnter: Session
  },
  {
    path: '/items',
    component: Items,
    meta: {
      type: 'logged',
      auth: {
        isGranted: true,
        User: {
          roles: [
            'ROLE_MASTER'
          ]
        }
      }
    },
    beforeEnter: Session
  },
  {
    path: '/item/:id',
    component: Item,
    meta: {
      type: 'logged',
      auth: {
        isGranted: true,
        User: {
          roles: [
            'ROLE_MASTER'
          ]
        }
      }
    },
    beforeEnter: Session
  }
]

const options = {
  mode: 'history',
  base: process.env.VUE_APP_PUBLIC_PATH,
  routes: routes
}

const router = new VueRouter(options)

export default router
