import Vue from 'vue'

class Auth {
  async login({data}) {
    return await Vue.axios
      .post('app/auth/login', data)
  }

  async user() {
    return await Vue.axios
      .get('app/auth/user')
  }

  async requestNewPassword({data}) {
    return await Vue.axios
      .post('app/auth/request-new-password', data)
  }

  async existNewPassword({newPasswordToken}) {
    return await Vue.axios
      .get('app/auth/exist-new-password/' + newPasswordToken)
  }

  async setNewPassword({newPasswordToken, data}) {
    return await Vue.axios
      .post('app/auth/set-new-password/' + newPasswordToken, data)
  }

  async logout() {
    return await Vue.axios
      .delete('auth/logout')
  }
}

const auth = new Auth()

export default auth
