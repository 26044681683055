<template>
  <v-btn
      :outlined="true"
      :loading="loading"
      :disabled="loading"
      color="white"
      @click="submit"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <v-icon>
      mdi-cached
    </v-icon>

    <slot/>
  </v-btn>
</template>

<script>
  import System from '@/services/system'

  export default {
    name: 'ClearCache',
    data() {
      return {
        loading: false
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      loadData() {

      },
      async submit() {
        this.loading = true

        await System
          .clearCache()
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })

            window.location.reload()
          })
          .catch((error) => {
            switch (error.response.status) {
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
