import store from '@/plugins/store'

const Session = function (to, from, next) {
  store.dispatch('auth/callUser')
    .finally(() => {
      switch (to.meta.type) {
        case 'logged':
          if (to.meta.auth.isGranted === store.state.auth.isGranted) {
            if (to.meta.auth.User.roles.includes(store.state.auth.User.role)) {
              next()
            } else {
              next('/error')
            }
          } else {
            next('/home')
          }

          break

        case 'unlogged':
          if (to.meta.auth.isGranted === store.state.auth.isGranted) {
            next()
          } else {
            next('/home')
          }

          break

        case 'none':
          next()

          break
      }
    })
}

export default Session
