const master = [
  {
    to: '/home',
    title: 'Nástenka',
    icon: 'mdi-home'
  },
  {
    title: 'Užívatelia',
    icon: 'mdi-account',
    value: false,
    subitems: [
      {
        to: '/admins',
        title: 'Administrátori'
      }
    ]
  },
  {
    to: '/mutations',
    title: 'Mutácie',
    icon: 'mdi-domain'
  },
  {
    to: '/imports',
    title: 'Importy',
    icon: 'mdi-import'
  },
  {
    to: '/items',
    title: 'Položky',
    icon: 'mdi-file'
  }
]

const worker = [
  {
    to: '/home',
    title: 'Nástenka',
    icon: 'mdi-home'
  }
]

const items = {
  master,
  worker
}

const menu = {
  items
}

export default menu
