<template>
  <v-card
      v-if="content && User"
  >
    <v-card-title>
      Nastaviť nové heslo do účtu
    </v-card-title>

    <v-card-text>
      <v-alert
          v-if="thanks"
          type="success"
          class="ma-0"
      >
        Nastavenie nového hesla bolo úspešné. Teraz sa môžeš prihlásiť :)
      </v-alert>

      <v-form
          v-else
          @submit.stop.prevent="submit"
      >
        <v-row>
          <v-col
              cols="12"
          >
            <v-text-field
                v-model="UserForm.plainPassword.first"
                :error-messages="errors.plainPassword && errors.plainPassword.first"
                :filled="true"
                :dense="true"
                hide-details="auto"
                label="Heslo"
                :persistent-hint="true"
                hint="Minimálne 6 znakov"
                type="password"
            />
          </v-col>

          <v-col
              cols="12"
          >
            <v-text-field
                v-model="UserForm.plainPassword.second"
                :error-messages="errors.plainPassword && errors.plainPassword.second"
                :filled="true"
                :dense="true"
                hide-details="auto"
                label="Heslo znova"
                type="password"
            />
          </v-col>

          <v-col
              cols="12"
          >
            <v-btn
                type="submit"
                :rounded="true"
                :outlined="true"
                :loading="loading"
                :disabled="loading"
                color="primary"
            >
              <v-icon>
                mdi-cog
              </v-icon>

              Nastaviť nové heslo
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
  import Auth from '@/services/auth'

  export default {
    name: 'SetNewPassword',
    data() {
      return {
        content: false,
        loading: false,
        thanks: false,
        User: null,
        UserForm: {},
        errors: {}
      }
    },
    mounted() {
      this.init()
    },
    watch: {
      $route(to, from) {
        if (to.path !== from.path) {
          this.init()
        }
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      refresh() {
        this.loadData('loading', true)
      },
      setBreadcrumbs() {
        this.$store.dispatch('layout/setBreadcrumbs', {
          breadcrumbs: [
            {
              text: 'Prihlásiť sa',
              to: '/login'
            },
            {
              text: 'Vyžiadať nové heslo',
              to: '/request-new-password'
            }
          ]
        })
      },
      loadData(key = 'content', value = false) {
        this[key] = value

        Auth
          .existNewPassword({
            newPasswordToken: this.$route.params.newPasswordToken
          })
          .then((response) => {
            this.setBreadcrumbs()

            this.$store.dispatch('layout/addBreadcrumbs', {
              breadcrumbs: [
                {
                  text: 'Nastaviť nové heslo',
                  to: '/set-new-password/' + response.data.data.User.newPasswordToken
                }
              ]
            })

            this.thanks = false

            this.User = response.data.data.User

            this.UserForm = {
              plainPassword: {
                first: '',
                second: ''
              }
            }

            this.errors = {}
          })
          .catch((error) => {
            switch (error.response.status) {
              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.$router.replace('/request-new-password')

                break
            }
          })
          .finally(() => {
            this[key] = !value
          })
      },
      async submit() {
        this.loading = true

        Auth
          .setNewPassword({
            newPasswordToken: this.User.newPasswordToken,
            data: this.UserForm
          })
          .then(() => {
            this.thanks = true
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
